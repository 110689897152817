import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './core.reducer';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once-guard';
import { IntercomService } from './services/monitoring/intercom.service';
import { BrandEffects } from './store/brand.effects';
import { provideAuth0 } from '@auth0/auth0-angular';
import { AuthInterceptor } from './interceptors/http-auth0.interceptor';
import { HttpErrorInterceptor } from './interceptors';

@NgModule({
    imports: [
        RouterModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: false,
                strictActionSerializability: false
            }
        }),
        EffectsModule.forRoot([BrandEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production
        })
    ],
    exports: [RouterModule, HttpClientModule],
    declarations: [],
    providers: [
        provideAuth0({
            domain: environment.idpUrl,
            clientId: environment.clientId,
            authorizationParams: {
                redirect_uri: window.location.search.includes('branch=')
                    ? `${window.location.origin}/${window.location.search}`
                    : `${window.location.origin}`,
                audience: 'https://bannerflow.com/resources/',
                scope: 'offline_access openid profile campaignservice bannerflow publishservice studio listservice'
            },
            httpInterceptor: {
                allowedList: [
                    `${environment.publishServiceUrl}*`,
                    `${environment.appUrl}*`,
                    `${environment.bannerflowUrl}*`,
                    `${environment.campaignServiceUrl}*`,
                    `${environment.idpUrl}*`,
                    `${environment.studioUrl}*`,
                    `${environment.excelGeneratorApiUrl}*`,
                    `${environment.bannerflowCdnUrl}*`,
                    `${environment.commonListViewUrl}*`,
                    `${environment.analyticsFrontendUrl}*`,
                    `${environment.apiGatewayUrl}*`
                ]
            },
            useRefreshTokens: true
        }),
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
    ] // these should be singleton
})
// Ensure that CoreModule is only loaded into AppModule
export class CoreModule extends EnsureModuleLoadedOnceGuard {
    // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule,
        @Optional() intercomService?: IntercomService
    ) {
        super(parentModule);

        intercomService?.init();
    }
}
