import { IAdValidationMap, ICheckedDecisionTree } from '@shared/models/campaigns';
import {
    DraftCampaignValidationActions,
    DraftCampaignValidationActionTypes
} from './draft-campaign-validation.actions';

export interface IDraftCampaignValidationState {
    hasChecked: boolean;
    hasCheckedAds: boolean;
    checkedDecisionTrees: ICheckedDecisionTree[];
    adValidationMap: IAdValidationMap;
}

const initialState: IDraftCampaignValidationState = {
    hasChecked: false,
    hasCheckedAds: false,
    checkedDecisionTrees: [],
    adValidationMap: {}
};

export function draftCampaignValidationReducer(
    state: IDraftCampaignValidationState = initialState,
    action: DraftCampaignValidationActions
): IDraftCampaignValidationState {
    switch (action.type) {
        case DraftCampaignValidationActionTypes.Validate:
            return {
                ...state,
                hasChecked: false,
                hasCheckedAds: false,
                checkedDecisionTrees: []
            };

        case DraftCampaignValidationActionTypes.ValidateComplete:
            return {
                ...state,
                hasChecked: true,
                checkedDecisionTrees: action.payload.validatedTrees
            };

        case DraftCampaignValidationActionTypes.ValidateAdsComplete:
            return {
                ...state,
                adValidationMap: action.payload.adValidationMap,
                hasCheckedAds: true
            };

        default:
            return state;
    }
}
