/* eslint-disable @typescript-eslint/no-unused-vars */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignFeatureGuard } from '@campaign/campaign-feature.guard';
import { CampaignPageResolver } from '@campaign/campaign-page.resolver';
import { AppAuthGuard } from '@core/authentication/app-auth.guard';
import { UserGuard } from '@core/authentication/user.guard';
import { AppInitResolver } from '@core/services/internal/app-init.resolver';
import { AuthComponent } from './auth.component';
import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';

const routes: Routes = [
    {
        path: ':accountSlug/:brandSlug',
        canActivate: [AppAuthGuard, UserGuard],
        resolve: {
            appInit: AppInitResolver
        },
        children: [
            {
                path: 'campaign/:campaignId',
                canActivate: [CampaignFeatureGuard],
                resolve: {
                    campaign: CampaignPageResolver
                },
                loadChildren: () =>
                    import('./pages/campaign/campaign-page.module').then(
                        (m) => m.CampaignPageModule
                    )
            },
            {
                path: 'creativeset/:creativesetId',
                canActivate: [CampaignFeatureGuard],
                loadChildren: () =>
                    import('./pages/campaign-create/campaign-create.module').then(
                        (m) => m.CampaignCreateModule
                    )
            },
            {
                path: 'publish',
                loadChildren: () =>
                    import('./pages/publish/publish.module').then((m) => m.PublishModule)
            }
        ]
    },
    {
        path: '',
        component: AuthComponent // used by auth0 with code param
    },
    {
        path: '404',
        component: NotFoundPageComponent
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

// Using preload all modules strategy because we have few modules
@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
