import { KeyValue } from './key-value.model';

export class SyncedProperty {
    public key: string;
    public children: SyncedProperty[];
    public syncedValues: KeyValue[];
    public selectedValue: string;

    public static deserialize(json: any): SyncedProperty | null {
        if (!json) {
            return null;
        }
        const syncedProperty: SyncedProperty = new SyncedProperty();
        syncedProperty.key = json.key;
        syncedProperty.syncedValues = [];

        if (json.children) {
            syncedProperty.children = json.children.map((_syncedProperty: any) =>
                SyncedProperty.deserialize(_syncedProperty)
            );
        }

        return syncedProperty;
    }

    /**
     * Serialize property into an object that can be sent to server through our api service
     */
    public serialize(): SyncedProperty {
        return null;
    }

    /**
     * Clone that take value copy of this object
     */
    public clone(): SyncedProperty {
        return null;
    }
}
