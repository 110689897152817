import { Action } from '@ngrx/store';
import { IFallbackCreative } from '@shared/models/campaigns';
import { ILocalization, ISize } from '@shared/models/common';
import { PublishOptionConfiguration } from '@shared/models/publish';

export enum BrandActionTypes {
    LoadSizes = '[Brand] Load Sizes',
    LoadSizesSuccess = '[Brand] Load Success Sizes',
    LoadLocalizations = '[Brand] Load Localizations',
    LoadLocalizationsSuccess = '[Brand] Load Success Localizations',
    LoadFallbackCreativeSet = '[Brand] Load Fallback Creative set',
    LoadFallbackCreativeSetSuccess = '[Brand] Load Fallback Creative Set Success',
    LoadPublishOptions = '[Brand] Load Publish Options',
    LoadPublishOptionsSuccess = '[Brand] Load Publish Options Success'
}

class ActionLoadSizes implements Action {
    public readonly type: BrandActionTypes.LoadSizes = BrandActionTypes.LoadSizes;
}

class ActionLoadLocalizations implements Action {
    public readonly type: BrandActionTypes.LoadLocalizations = BrandActionTypes.LoadLocalizations;
}

class ActionLoadFallbackCreativeSet implements Action {
    public readonly type: BrandActionTypes.LoadFallbackCreativeSet =
        BrandActionTypes.LoadFallbackCreativeSet;
}

export class ActionLoadPublishOptions implements Action {
    public readonly type = BrandActionTypes.LoadPublishOptions;
}

export class ActionLoadSizesSuccess implements Action {
    public readonly type: BrandActionTypes.LoadSizesSuccess = BrandActionTypes.LoadSizesSuccess;
    constructor(public payload: { sizes: ISize[] }) {}
}

export class ActionLoadLocalizationsSuccess implements Action {
    public readonly type: BrandActionTypes.LoadLocalizationsSuccess =
        BrandActionTypes.LoadLocalizationsSuccess;
    constructor(public payload: { localizations: ILocalization[] }) {}
}

export class ActionLoadFallbackCreativeSetSuccess implements Action {
    public readonly type: BrandActionTypes.LoadFallbackCreativeSetSuccess =
        BrandActionTypes.LoadFallbackCreativeSetSuccess;
    constructor(public payload: { fallbackCreatives: IFallbackCreative[] }) {}
}

export class ActionLoadPublishOptionsSuccess implements Action {
    public readonly type = BrandActionTypes.LoadPublishOptionsSuccess;
    constructor(public payload: { publishOptions: PublishOptionConfiguration[] }) {}
}

export type BrandActions =
    | ActionLoadSizes
    | ActionLoadSizesSuccess
    | ActionLoadLocalizations
    | ActionLoadLocalizationsSuccess
    | ActionLoadFallbackCreativeSet
    | ActionLoadFallbackCreativeSetSuccess
    | ActionLoadPublishOptions
    | ActionLoadPublishOptionsSuccess;
