import { Injectable } from '@angular/core';
import { BannerflowApiService } from '@core/services/bannerflow';
import { PublishApiService } from '@core/services/publish';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ILocalization, ISize } from '@shared/models/common';
import { PublishOptionConfiguration } from '@shared/models/publish';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    ActionLoadLocalizationsSuccess,
    ActionLoadPublishOptionsSuccess,
    ActionLoadSizesSuccess,
    BrandActionTypes
} from './brand.actions';

@Injectable()
export class BrandEffects {
    constructor(
        private actions$: Actions,
        private bannerflowService: BannerflowApiService,
        private publishService: PublishApiService
    ) {}

    public loadSizes$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandActionTypes.LoadSizes),
            switchMap(() =>
                this.bannerflowService
                    .getSizes()
                    .pipe(map((sizes: ISize[]) => new ActionLoadSizesSuccess({ sizes })))
            )
        )
    );

    public loadLocalizations$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandActionTypes.LoadLocalizations),
            switchMap(() =>
                this.bannerflowService
                    .getLocalizations()
                    .pipe(
                        map(
                            (localizations: ILocalization[]) =>
                                new ActionLoadLocalizationsSuccess({ localizations })
                        )
                    )
            )
        )
    );

    public loadPublishOptions$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandActionTypes.LoadPublishOptions),
            switchMap(() =>
                from(this.publishService.getPublishOptionConfigurations()).pipe(
                    map(
                        (publishOptions: PublishOptionConfiguration[]) =>
                            new ActionLoadPublishOptionsSuccess({ publishOptions })
                    )
                )
            )
        )
    );
}
