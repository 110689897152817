import { Action } from '@ngrx/store';
import { IAdValidationMap, ICheckedDecisionTree } from '@shared/models/campaigns';

export enum DraftCampaignValidationActionTypes {
    Validate = '[DraftCampaignValidation] Validate',
    ValidateComplete = '[DraftCampaignValidation] Validate Complete',
    ValidateFailure = '[DraftCampaignValidation] Validate Failure',
    ValidateAdsComplete = '[DraftCampaignValidation] Validate Ads Complete'
}

export class ActionValidateDraftCampaign implements Action {
    public readonly type = DraftCampaignValidationActionTypes.Validate;
}
export class ActionValidateDraftCampaignComplete implements Action {
    public readonly type = DraftCampaignValidationActionTypes.ValidateComplete;
    constructor(public payload: { validatedTrees: ICheckedDecisionTree[] }) {}
}

export class ActionValidateAdsComplete implements Action {
    public readonly type = DraftCampaignValidationActionTypes.ValidateAdsComplete;
    constructor(public payload: { adValidationMap: IAdValidationMap }) {}
}

export type DraftCampaignValidationActions =
    | ActionValidateDraftCampaign
    | ActionValidateDraftCampaignComplete
    | ActionValidateAdsComplete;
