import { IAutoOptimisationData } from '@shared/models/campaigns';
import { AutoOptimisationActions, AutoOptimisationActionTypes } from './auto-optimisation.actions';
export interface IAutoOptimisationState {
    autoOptimisationData: IAutoOptimisationData[];
    adGroupIds: string[];
}

export const initialState: IAutoOptimisationState = {
    autoOptimisationData: [],
    adGroupIds: []
};

export function autoOptimisationReducer(
    state: IAutoOptimisationState = initialState,
    action: AutoOptimisationActions
): IAutoOptimisationState {
    switch (action.type) {
        case AutoOptimisationActionTypes.LoadAutoOptimisationData:
            return state;

        case AutoOptimisationActionTypes.LoadAutoOptimisationDataSuccess:
            const { autoOptimisationData } = action.payload;

            if (!autoOptimisationData || autoOptimisationData.length === 0) {
                return state;
            }

            return {
                ...state,
                autoOptimisationData
            };

        default:
            return state;
    }
}
