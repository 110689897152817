import { ICreative } from '@shared/models/studio';
import { CreativeUtilities } from '@shared/models/studio/utilities/creative-utilities';
import { INodeProperties } from '../../draft-campaign';
import { IAdCreativePair } from '../ad-creative-pair.model';
import { AnyAd } from '../any-ad.model';
import { AdUtilities } from './ad-utilities';

export class AdCreativePairUtilities {
    public ads: AnyAd[];
    public creatives: ICreative[];

    constructor(ads: AnyAd[], creatives: ICreative[]) {
        this.ads = ads;
        this.creatives = creatives;
    }

    // TODO this only checks if the ad is included in the pair constructor
    // maybe we should rename it
    public isAdMatching(ad: AnyAd): boolean {
        return this.getAllMatchingPairs().some((pair) => pair.ad.id === ad.id);
    }

    public getMatchingCreatives(ad: AnyAd, versionId: string): ICreative[] {
        const pair: IAdCreativePair[] = this.getAllMatchingPairs().filter(
            (matchingPair) =>
                matchingPair.ad.id === ad.id && matchingPair.creative.version.id === versionId
        );

        return pair ? pair.map((matchedPair) => matchedPair.creative) : [];
    }

    public getSingleMatchingPairsForVersion(versionId: string): IAdCreativePair[] {
        return this.ads.reduce((result: IAdCreativePair[], ad: AnyAd) => {
            const creatives: ICreative[] = this.getMatchingCreativesForAd(ad).filter(
                (creative) => creative?.version?.id === versionId
            );

            if (creatives.length === 1) {
                const pair = { ad, creative: creatives[0] };
                return [...result, pair];
            }

            return result;
        }, []);
    }

    private getAllMatchingPairs(): IAdCreativePair[] {
        return this.ads.reduce((result: IAdCreativePair[], ad: AnyAd) => {
            const creatives: ICreative[] = this.getMatchingCreativesForAd(ad);
            const pairs = creatives.map((creative) => ({ ad, creative }));

            return [...result, ...pairs];
        }, []);
    }

    public toFirstMatchingNodeProperties(): INodeProperties {
        const dictionary: INodeProperties = {};

        this.ads.forEach((ad) => {
            const creatives: ICreative[] = this.getMatchingCreativesForAd(ad);

            if (creatives.length === 1) {
                dictionary[ad?.id] = creatives[0]?.id;
            }
        });

        return dictionary;
    }

    /**
     * Returns all matching creatives for ad it can be more than one if the creative has multiple versions of same language
     * e.g. English A, English B
     *
     * Returns creatives sorted by vesion name
     *
     * @param ad
     */
    private getMatchingCreativesForAd(ad: AnyAd): ICreative[] {
        const creatives: ICreative[] = this.creatives.filter((creative) =>
            AdUtilities.canAssign(ad, creative)
        );
        creatives.sort(CreativeUtilities.sortCreativesByRatioWidthLocale);

        return creatives;
    }
}
