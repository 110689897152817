import { Action } from '@ngrx/store';
import { ICampaign, IDraftCampaign } from '@shared/models/campaigns';
import { ICampaignError } from '@shared/models/campaigns/validation';

export enum DraftCampaignActionTypes {
    Load = '[DraftCampaign] Load',
    LoadSuccess = '[DraftCampaign] Load Successful',
    LoadLiveCampaign = '[DraftCampaign] Load Live Campaign',
    LoadLiveCampaignSuccess = '[DraftCampaign] Load Live Campaign Successful',
    Update = '[DraftCampaign] Update',
    SetHistory = '[DraftCampaign] Set History',
    Save = '[DraftCampaign] Save',
    SaveSuccess = '[DraftCampaign] Saved',
    SaveFailure = '[DraftCampaign] Save failed',
    Undo = '[DraftCampaign] Undo',
    PushLive = '[DraftCampaign] Push Live',
    UpdateAndPushLive = '[DraftCampaign] Update and Push Live',
    PushLiveSuccess = '[DraftCampaign] Push Live Success',
    PushLiveFailure = '[DraftCampaign] Push Live Failure',
    PushLiveCancelled = '[DraftCampaign] Push Live Cancelled',
    Discard = '[DraftCampaign] Discard Changes',
    DiscardSuccess = '[DraftCampaign] Discard Changes Success',
    SortAllAds = '[DraftCampaign] Sort All Ads'
}

export class ActionLoadDraftCampaign implements Action {
    public readonly type: DraftCampaignActionTypes.Load = DraftCampaignActionTypes.Load;
    constructor(public payload: { campaign: ICampaign }) {}
}

export class ActionLoadDraftCampaignSuccess implements Action {
    public readonly type: DraftCampaignActionTypes.LoadSuccess =
        DraftCampaignActionTypes.LoadSuccess;
    constructor(public payload: { draftCampaign: IDraftCampaign }) {}
}

export class ActionLoadLiveCampaign implements Action {
    public readonly type: DraftCampaignActionTypes.LoadLiveCampaign =
        DraftCampaignActionTypes.LoadLiveCampaign;
    constructor(public payload: { campaignId: string }) {}
}
export class ActionLoadLiveCampaignSuccess implements Action {
    public readonly type: DraftCampaignActionTypes.LoadLiveCampaignSuccess =
        DraftCampaignActionTypes.LoadLiveCampaignSuccess;
    constructor(public payload: { liveCampaign: IDraftCampaign }) {}
}

export class ActionUpdateDraftCampaign implements Action {
    public readonly type: DraftCampaignActionTypes.Update = DraftCampaignActionTypes.Update;
    constructor(public payload: { changes: Partial<IDraftCampaign> }) {}
}
export class ActionUpdateAndPushDraftCampaign implements Action {
    public readonly type = DraftCampaignActionTypes.UpdateAndPushLive;
    constructor(public payload: { changes: Partial<IDraftCampaign> }) {}
}
export class ActionSetDraftCampaignHistory implements Action {
    public readonly type: DraftCampaignActionTypes.SetHistory = DraftCampaignActionTypes.SetHistory;
    constructor(public payload: { history: IDraftCampaign[] }) {}
}
export class ActionSaveDraftCampaign implements Action {
    public readonly type: DraftCampaignActionTypes.Save = DraftCampaignActionTypes.Save;
}
export class ActionSaveDraftCampaignSuccess implements Action {
    public readonly type: DraftCampaignActionTypes.SaveSuccess =
        DraftCampaignActionTypes.SaveSuccess;
    constructor(public payload: { draftCampaign: IDraftCampaign }) {}
}
export class ActionSaveDraftCampaignFailure implements Action {
    public readonly type: DraftCampaignActionTypes.SaveFailure =
        DraftCampaignActionTypes.SaveFailure;
    constructor(public payload: { error: ICampaignError }) {}
}
export class ActionUndoDraftCampaign implements Action {
    public readonly type: DraftCampaignActionTypes.Undo = DraftCampaignActionTypes.Undo;
}
export class ActionPushLiveDraftCampaign implements Action {
    public readonly type: DraftCampaignActionTypes.PushLive = DraftCampaignActionTypes.PushLive;
    constructor(public payload: { hideDialog: boolean }) {}
}
export class ActionPushLiveDraftCampaignSuccess implements Action {
    public readonly type: DraftCampaignActionTypes.PushLiveSuccess =
        DraftCampaignActionTypes.PushLiveSuccess;
    constructor(public payload: { campaign: ICampaign }) {}
}
export class ActionPushLiveDraftCampaignFailure implements Action {
    public readonly type: DraftCampaignActionTypes.PushLiveFailure =
        DraftCampaignActionTypes.PushLiveFailure;
    constructor(public payload: { error: ICampaignError }) {}
}
export class ActionPushLiveDraftCampaignCancelled implements Action {
    public readonly type: DraftCampaignActionTypes.PushLiveCancelled =
        DraftCampaignActionTypes.PushLiveCancelled;
}
export class ActionDiscardDraftCampaign implements Action {
    public readonly type: DraftCampaignActionTypes.Discard = DraftCampaignActionTypes.Discard;
}
export class ActionDiscardDraftCampaignSuccess implements Action {
    public readonly type: DraftCampaignActionTypes.DiscardSuccess =
        DraftCampaignActionTypes.DiscardSuccess;
    constructor(public payload: { draftCampaign: IDraftCampaign }) {}
}
export class ActionDraftCampaignSortAllAds implements Action {
    public readonly type: DraftCampaignActionTypes.SortAllAds = DraftCampaignActionTypes.SortAllAds;
    constructor(public payload: { draftCampaign: IDraftCampaign }) {}
}

export type DraftCampaignActions =
    | ActionLoadDraftCampaign
    | ActionLoadDraftCampaignSuccess
    | ActionLoadLiveCampaign
    | ActionLoadLiveCampaignSuccess
    | ActionSetDraftCampaignHistory
    | ActionUpdateDraftCampaign
    | ActionUpdateAndPushDraftCampaign
    | ActionSaveDraftCampaign
    | ActionSaveDraftCampaignSuccess
    | ActionSaveDraftCampaignFailure
    | ActionUndoDraftCampaign
    | ActionPushLiveDraftCampaign
    | ActionPushLiveDraftCampaignSuccess
    | ActionPushLiveDraftCampaignFailure
    | ActionPushLiveDraftCampaignCancelled
    | ActionDiscardDraftCampaign
    | ActionDiscardDraftCampaignSuccess
    | ActionDraftCampaignSortAllAds;
