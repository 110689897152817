export class PublishOption {
    public id: string;
    public name: string;
    public type: string;
    public description: string;
    public isEnabled: boolean;
    public supportsHeavyVideo: boolean;

    constructor(
        name: string,
        type: string,
        id?: string,
        description?: string,
        loaded?: boolean,
        expaned?: boolean,
        isEnabled?: boolean,
        supportsHeavyVideo?: boolean
    ) {
        this.name = name;
        this.type = type;
        this.id = id;
        this.description = description;
        this.isEnabled = isEnabled;
        this.supportsHeavyVideo = supportsHeavyVideo;
    }

    public static deserialize(json: any): PublishOption | null {
        if (!json) {
            return null;
        }

        return new PublishOption(
            json.name,
            json.type,
            json.id,
            json.description,
            false,
            false,
            json.isEnabled,
            json.supportsHeavyVideo
        );
    }

    /**
     * Serialize PublishOption Reference into an object that can be sent to server through our api service
     */
    public serialize(): PublishOption {
        return new PublishOption(this.name, this.type, this.id);
    }
}
