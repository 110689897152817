import { Action } from '@ngrx/store';
import { IAutoOptimisationData } from '@shared/models/campaigns';

export enum AutoOptimisationActionTypes {
    LoadAutoOptimisationData = '[Auto Optimisation] Load data',
    LoadAutoOptimisationDataSuccess = '[Auto Optimisation] Load data success'
}

export class ActionLoadAutoOptimisationData implements Action {
    public readonly type: AutoOptimisationActionTypes.LoadAutoOptimisationData =
        AutoOptimisationActionTypes.LoadAutoOptimisationData;
}

export class ActionLoadAutoOptimisationDataSuccess implements Action {
    public readonly type: AutoOptimisationActionTypes.LoadAutoOptimisationDataSuccess =
        AutoOptimisationActionTypes.LoadAutoOptimisationDataSuccess;
    constructor(public payload: { autoOptimisationData: IAutoOptimisationData[] }) {}
}

export type AutoOptimisationActions =
    | ActionLoadAutoOptimisationData
    | ActionLoadAutoOptimisationDataSuccess;
