export class PublishProperty {
    public key: string;
    public valueType: string;
    public isRequired: boolean;
    public defaultValue?: any;

    public static deserialize(json: any): PublishProperty | null {
        if (!json) {
            return null;
        }
        const property: PublishProperty = new PublishProperty();
        property.key = json.key;
        property.valueType = json.valueType;
        property.isRequired = json.isRequired;
        if (property.valueType === 'String') {
            property.defaultValue = json.defaultValue;
        } else if (property.valueType === 'Boolean') {
            property.defaultValue = !!(json.defaultValue === 'true');
        }

        return property;
    }

    /**
     * Serialize property into an object that can be sent to server through our api service
     */
    public serialize(): PublishProperty {
        const property: PublishProperty = new PublishProperty();

        property.key = this.key;
        property.isRequired = this.isRequired;
        property.valueType = this.valueType;

        if (
            this.defaultValue !== undefined &&
            (this.valueType === 'String' || this.valueType === 'Boolean')
        ) {
            property.defaultValue = this.defaultValue.toString();
        }

        return property;
    }

    /**
     * Clone that take value copy of this object
     */
    public clone(): PublishProperty {
        const property: PublishProperty = new PublishProperty();

        property.key = this.key;
        property.valueType = this.valueType;
        property.isRequired = this.isRequired;
        property.defaultValue = this.defaultValue;

        return property;
    }
}
