export class TagSetting {
    public key: string;
    public isEnabled?: boolean;
    public value?: boolean = false;

    /**
     * Deserialize TagSetting into an object that can be use in frontend components
     */
    public static deserialize(json: any): TagSetting | null {
        if (!json) {
            return null;
        }
        const adTagSetting: TagSetting = new TagSetting();
        adTagSetting.key = json.key;
        adTagSetting.isEnabled = json.isEnabled;
        adTagSetting.value = false;

        return adTagSetting;
    }

    /**
     * Serialize TagSetting into an object that can be sent to server through our api service
     */
    public serialize(): TagSetting {
        const adTagSetting: TagSetting = new TagSetting();
        adTagSetting.key = this.key;
        adTagSetting.isEnabled = this.isEnabled;
        adTagSetting.value = this.value;

        return adTagSetting;
    }

    /**
     * Clone TagSetting into an object to lose the state of it if needed
     */
    public clone(): TagSetting {
        const adTagSetting: TagSetting = new TagSetting();
        adTagSetting.key = this.key;
        adTagSetting.isEnabled = this.isEnabled;
        adTagSetting.value = this.value;

        return adTagSetting;
    }
}
