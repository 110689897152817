import { Action } from '@ngrx/store';
import { User } from '@shared/models/bannerflow';

export enum UserActionTypes {
    LoadUserSuccess = '[User] Load Success'
}

export class ActionLoadUserSuccess implements Action {
    public readonly type: UserActionTypes.LoadUserSuccess = UserActionTypes.LoadUserSuccess;
    constructor(public payload: { user: User }) {}
}

export type UserActions = ActionLoadUserSuccess;
