import { Destination } from './destination.model';
import { Authentication } from './authentication.model';
import { AdTag } from './ad-tag.model';
import { SyncedProperty } from './synced-property.model';
import { PublishProperty } from './publish-property.model';
import { StaticProperty } from './static-property.model';

const typeLabelDictionary: { [type: string]: string } = {
    Html5Export: 'Html5 Export',
    other: ''
};

export type SupportedAdType = 'Image' | 'Video' | 'Html';

export class PublishOptionConfiguration {
    public id: string;
    public name?: string;
    public clickThroughBy: string;
    public isEnabled?: boolean;
    public accountId: string;
    public publishOptionId: string;
    public type?: string;
    public destination?: Destination;
    public authentication: Authentication = new Authentication();
    public adNamingConvention: string;
    public adTag?: AdTag | null;
    public brandIds: string[];
    public syncedProperties?: SyncedProperty[] | null;
    public staticProperties?: StaticProperty[] | null;
    public publishProperties?: PublishProperty[] | null;
    public supportedAdType: SupportedAdType;
    public typeLabel: string;
    public supportsHeavyVideo: boolean;

    public static deserialize(json: any): PublishOptionConfiguration | null {
        if (!json) {
            return null;
        }
        const publishOptionConfiguration: PublishOptionConfiguration =
            new PublishOptionConfiguration();
        publishOptionConfiguration.id = json.id;
        publishOptionConfiguration.name = json.name;
        publishOptionConfiguration.type = json.type;
        publishOptionConfiguration.publishOptionId = json.publishOptionId;
        publishOptionConfiguration.authentication = Authentication.deserialize(json.authentication);
        publishOptionConfiguration.destination = json.destination;
        publishOptionConfiguration.clickThroughBy = json.clickThroughBy;
        publishOptionConfiguration.adNamingConvention = json.adNamingConvention;
        publishOptionConfiguration.adTag = json.adTag ? AdTag.deserialize(json.adTag) : null;
        publishOptionConfiguration.brandIds = json.brandIds;
        publishOptionConfiguration.accountId = json.accountId;
        publishOptionConfiguration.supportedAdType = json.supportedAdType;
        publishOptionConfiguration.supportsHeavyVideo = json.supportsHeavyVideo;

        if (json.syncedProperties) {
            publishOptionConfiguration.syncedProperties = json.syncedProperties.map(
                (syncedProperty: any) => SyncedProperty.deserialize(syncedProperty)
            );
        }

        if (json.staticProperties) {
            publishOptionConfiguration.staticProperties = json.staticProperties.map(
                (property: any) => StaticProperty.deserialize(property)
            );
        }

        if (json.publishProperties) {
            publishOptionConfiguration.publishProperties = json.publishProperties.map(
                (property: any) => PublishProperty.deserialize(property)
            );
        }

        const typeLabel: string = typeLabelDictionary[json.type] || json.type;
        publishOptionConfiguration.typeLabel = typeLabel;

        return publishOptionConfiguration;
    }

    /**
     * Serialize Publish Option into an object that can be sent to server through our api service
     */
    public serialize(): PublishOptionConfiguration {
        const publishOptionConfiguration: PublishOptionConfiguration =
            new PublishOptionConfiguration();
        publishOptionConfiguration.id = this.id;
        publishOptionConfiguration.name = this.name;
        publishOptionConfiguration.authentication = this.authentication
            ? this.authentication.serialize()
            : undefined;
        publishOptionConfiguration.type = this.type;
        publishOptionConfiguration.publishOptionId = this.publishOptionId;
        publishOptionConfiguration.clickThroughBy = this.clickThroughBy;
        publishOptionConfiguration.adNamingConvention = this.adNamingConvention;
        publishOptionConfiguration.adTag = this.adTag ? this.adTag.serialize() : this.adTag;
        publishOptionConfiguration.brandIds = this.brandIds;
        publishOptionConfiguration.supportedAdType = this.supportedAdType;

        if (this.staticProperties) {
            publishOptionConfiguration.staticProperties = this.staticProperties
                ? this.staticProperties.map((property) => property.serialize())
                : this.staticProperties;
        }

        if (this.publishProperties) {
            publishOptionConfiguration.publishProperties = this.publishProperties
                ? this.publishProperties.map((property) => property.serialize())
                : this.publishProperties;
        }

        return publishOptionConfiguration;
    }

    /**
     * Clone that take value copy of this object
     */
    public clone(): PublishOptionConfiguration {
        const publishOptionConfiguration: PublishOptionConfiguration =
            new PublishOptionConfiguration();
        publishOptionConfiguration.id = this.id;
        publishOptionConfiguration.name = this.name;
        publishOptionConfiguration.type = this.type;
        publishOptionConfiguration.authentication = this.authentication
            ? this.authentication.clone()
            : undefined;
        publishOptionConfiguration.destination = this.destination;
        publishOptionConfiguration.clickThroughBy = this.clickThroughBy;
        publishOptionConfiguration.adNamingConvention = this.adNamingConvention;
        publishOptionConfiguration.adTag = this.adTag ? this.adTag.clone() : null;
        publishOptionConfiguration.brandIds = this.brandIds;
        publishOptionConfiguration.supportedAdType = this.supportedAdType;

        if (this.syncedProperties) {
            publishOptionConfiguration.syncedProperties = this.syncedProperties.map(
                (property: any) => property.clone()
            );
        }

        if (this.staticProperties) {
            publishOptionConfiguration.staticProperties = this.staticProperties.map(
                (property: any) => property.clone()
            );
        }

        if (this.publishProperties) {
            publishOptionConfiguration.publishProperties = this.publishProperties.map(
                (property: any) => property.clone()
            );
        }

        return publishOptionConfiguration;
    }
}
