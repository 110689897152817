import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { IAppState, selectUserState } from '@core/core.reducer';
import { KnownUserFeature } from '@core/services/bannerflow/user.service';
import { Store } from '@ngrx/store';
import { User } from '@shared/models/bannerflow';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CampaignFeatureGuard implements CanActivate {
    constructor(private store: Store<IAppState>, private router: Router) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return this.store.select(selectUserState).pipe(
            filter((userState) => !!userState.user),
            map(
                (userState) =>
                    CampaignFeatureGuard.hasCampaignFeature(userState.user) ||
                    this.router.parseUrl('oops')
            )
        );
    }

    public static hasCampaignFeature(user: User): boolean {
        return user.account.features.includes(KnownUserFeature.CampaignManager);
    }
}
