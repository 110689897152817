import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { CYPRESS_FLAG_KEY } from '@cypress/cypress-flag.const';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authHttpInterceptor: AuthHttpInterceptor) {}

    private isCypress(): boolean {
        return !!localStorage.getItem(CYPRESS_FLAG_KEY);
    }

    public intercept(
        request: HttpRequest<HttpHeaders>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.isCypress()) {
            return next.handle(request);
        }
        return this.authHttpInterceptor.intercept(request, next);
    }
}
