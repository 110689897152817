import { ICampaign, IValidationMap } from '@shared/models/campaigns';
import { PublishAttemptUtilities } from '@shared/models/campaigns/campaign/publish-attempt-utilities';
import { CampaignActions, CampaignActionTypes } from './campaign.actions';

export interface ICampaignState {
    campaign: ICampaign;
    initPublish: boolean;
    heavyVideoAdsMap: IValidationMap;
    adsPublishedToNonHeavyVideoSupport: string[];
    currentPublishedAdIds: string[];
}

const initialState: ICampaignState = {
    campaign: undefined,
    initPublish: false,
    heavyVideoAdsMap: {},
    adsPublishedToNonHeavyVideoSupport: [],
    currentPublishedAdIds: []
};

export function campaignReducer(
    state: ICampaignState = initialState,
    action: CampaignActions
): ICampaignState {
    switch (action.type) {
        case CampaignActionTypes.LoadSuccess:
            const { campaign } = action.payload;
            return {
                ...state,
                campaign,
                currentPublishedAdIds: PublishAttemptUtilities.getPublishedAdIdsFromAttempts(
                    campaign.attempts,
                    campaign.ads.map((ad) => ad.id)
                )
            };

        case CampaignActionTypes.Update:
            return {
                ...state,
                campaign: action.payload.campaign
            };

        case CampaignActionTypes.InitPublish:
            return {
                ...state,
                initPublish: true
            };

        case CampaignActionTypes.InitPublishSuccess:
        case CampaignActionTypes.InitPublishFailure:
            return {
                ...state,
                initPublish: false
            };

        case CampaignActionTypes.AssignPublishedToNonHeavyVideoSupportAdMap:
            const { adsPublishedToNonHeavyVideoSupport } = action.payload;
            return {
                ...state,
                adsPublishedToNonHeavyVideoSupport
            };

        case CampaignActionTypes.ReceivedCampaignPublishStatusUpdate:
            return {
                ...state,
                campaign: {
                    ...state.campaign,
                    status: action.payload.campaignPublishStatus.campaignStatus
                }
            };

        case CampaignActionTypes.ReceivedAdPublishStatusesUpdate:
            const { adPublishStatuses } = action.payload;
            const publishedAdIdsFromUpdate =
                PublishAttemptUtilities.getPublishedAdIdsFromStatusUpdate(adPublishStatuses);

            return {
                ...state,
                currentPublishedAdIds: [
                    ...new Set([...state.currentPublishedAdIds, ...publishedAdIdsFromUpdate])
                ]
            };

        default:
            return state;
    }
}
