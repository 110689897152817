import { IDecisionTree } from './decision-tree';
import {
    AnyNode,
    AutoOptimisationNodeUtilities,
    CreativeNodeUtilities,
    ICreativeNode
} from './nodes';

// TODO make tree utils immutable
export class DecisionTreeUtilities {
    // static method to get children, It is easy to maintain
    public static getChildren(node: AnyNode): AnyNode[] {
        if (!node) {
            return undefined;
        }

        if (!node.children) {
            return undefined;
        }

        return node.children;
    }

    // recursively flattens tree and returns all descendants in array
    public static getAllDescendants(parents: AnyNode[]): AnyNode[] {
        const desendants: AnyNode[] = parents.reduce((acc, child) => {
            const grandChildren: AnyNode[] = this.getChildren(child);

            return this.getAllDescendants(grandChildren || []);
        }, []);

        return parents.concat(desendants);
    }

    // get all trees with an Auto-opt node inside
    public static getTreesWithAutoOptimisation(decisionTrees: IDecisionTree[]): IDecisionTree[] {
        if (!decisionTrees) {
            return undefined;
        }

        return decisionTrees.filter((tree) => AutoOptimisationNodeUtilities.isAONode(tree.root));
    }

    /**
     * @deprecated use findNode() with parentId instead
     * @param childNode
     * @param root
     */
    public static getParent(childNode: AnyNode, root: AnyNode): AnyNode {
        // gets all desencants from root
        const allNodes: AnyNode[] = this.getAllDescendants([root]);
        const foundParent: AnyNode = allNodes.find((node) =>
            this.getChildren(node).find((_node) => childNode.id === _node.id)
        );

        return foundParent;
    }

    // finds node in given node or root
    public static findNode(root: AnyNode, nodeId: string): AnyNode {
        return this.getAllDescendants([root]).find((node) => nodeId === node.id);
    }

    // finds node in given node or root
    public static findTreeOfNode(decisionTrees: IDecisionTree[], nodeId: string): IDecisionTree {
        let foundNode: AnyNode;

        for (const tree of decisionTrees) {
            foundNode = this.findNode(tree.root, nodeId);

            if (!foundNode) {
                continue;
            } else {
                return tree;
            }
        }
    }

    // return all creative nodes in given node or root
    public static getAllCreativeNodes(root: AnyNode): ICreativeNode[] {
        return this.getAllDescendants([root])
            .filter((node) => CreativeNodeUtilities.isCreativeNode(node))
            .map((node) => node as ICreativeNode);
    }
}
