import { ErrorHandler, Injectable, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
    private readonly REFRESH_TOKEN_ERROR: string =
        "Missing Refresh Token (audience: 'https://bannerflow.com/resources/', scope: 'openid offline_access profile campaignservice bannerflow publishservice studio listservice')";

    private sentinelService = inject(SentinelService);

    public handleError(error: any): void {
        if (error.message === this.REFRESH_TOKEN_ERROR) {
            return;
        }
        environment.stage === 'sandbox' || environment.stage === 'production'
            ? this.sentinelService.error(error)
            : console.error('An error occurred:', error);
    }
}
