import { IFallbackCreative } from '@shared/models/campaigns';
import { ILocalization, ISize } from '@shared/models/common';
import { PublishOptionConfiguration } from '@shared/models/publish';
import { BrandActions, BrandActionTypes } from './brand.actions';

export interface IBrandState {
    sizes: ISize[];
    localizations: ILocalization[];
    fallbackCreatives: IFallbackCreative[];
    fallbackCreativeSetId: string;
    publishOptions: PublishOptionConfiguration[];
}

const initialState: IBrandState = {
    sizes: [],
    localizations: [],
    fallbackCreatives: [],
    fallbackCreativeSetId: '',
    publishOptions: []
};

export function brandReducer(state: IBrandState = initialState, action: BrandActions): IBrandState {
    switch (action.type) {
        case BrandActionTypes.LoadSizes: {
            return { ...state, sizes: [] };
        }
        case BrandActionTypes.LoadLocalizations: {
            return { ...state, localizations: [] };
        }
        case BrandActionTypes.LoadSizesSuccess: {
            return { ...state, sizes: action.payload.sizes };
        }
        case BrandActionTypes.LoadLocalizationsSuccess: {
            return { ...state, localizations: action.payload.localizations };
        }
        case BrandActionTypes.LoadFallbackCreativeSet: {
            return { ...state, fallbackCreatives: [] };
        }
        case BrandActionTypes.LoadFallbackCreativeSetSuccess: {
            const { fallbackCreatives } = action.payload;
            let fallbackCreativeSetId = '';

            if (fallbackCreatives.length) {
                fallbackCreativeSetId = fallbackCreatives[0].creativeset?.id;
            }

            return {
                ...state,
                fallbackCreatives,
                fallbackCreativeSetId
            };
        }
        case BrandActionTypes.LoadPublishOptionsSuccess: {
            return {
                ...state,
                publishOptions: action.payload.publishOptions
            };
        }
        default:
            return state;
    }
}
