import { UserActions, UserActionTypes } from './user.actions';
import { User } from '@shared/models/bannerflow';

export interface IUserState {
    isLoaded: boolean;
    user: User;
}

const initialState: IUserState = {
    isLoaded: false,
    user: undefined
};

export function userReducer(state: IUserState = initialState, action: UserActions): IUserState {
    switch (action.type) {
        case UserActionTypes.LoadUserSuccess: {
            return { ...state, isLoaded: true, user: action.payload.user };
        }
        default:
            return state;
    }
}
