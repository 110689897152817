import { Action } from '@ngrx/store';
import {
    IAdPublishStatusUpdate,
    ICampaign,
    ICampaignPublishStatusUpdate
} from '@shared/models/campaigns';
import { ICampaignError } from '@shared/models/campaigns/validation';
import { IPublishReadyAd } from '@shared/models/publish/publish-ready-ad';

export enum CampaignActionTypes {
    Load = '[Campaign] Load',
    LoadSuccess = '[Campaign] Load Success',
    Rename = '[Campaign] Rename',
    Update = '[Campaign] Update',
    ReceivedAdPublishStatusesUpdate = '[Campaign] Received Ad Publish Statuses Update',
    ReceivedCampaignPublishStatusUpdate = '[Campaign] Received Campaign Publish Status Update',
    UpdateDraft = '[Campaign] Update Draft',
    InitPublish = '[Campaign] Init Publish',
    InitPublishSuccess = '[Campaign] Init Publish Succeess',
    InitPublishFailure = '[Campaign] Init Publish Failure',
    AssignPublishedToNonHeavyVideoSupportAdMap = '[Campaign] Assign Published To Non HV Support Options Ads'
}

export class ActionLoadCampaign implements Action {
    public readonly type = CampaignActionTypes.Load;
    constructor(public payload: { campaignId: string }) {}
}
export class ActionLoadCampaignSuccess implements Action {
    public readonly type = CampaignActionTypes.LoadSuccess;
    constructor(public payload: { campaign: ICampaign }) {}
}

export class ActionRenameCampaign implements Action {
    public readonly type = CampaignActionTypes.Rename;
    constructor(public payload: { campaignName: string }) {}
}

export class ActionUpdateCampaign implements Action {
    public readonly type = CampaignActionTypes.Update;
    constructor(public payload: { campaign: ICampaign }) {}
}

export class ActionReceivedAdPublishStatusesUpdate implements Action {
    public readonly type = CampaignActionTypes.ReceivedAdPublishStatusesUpdate;
    constructor(public payload: { adPublishStatuses: IAdPublishStatusUpdate[] }) {}
}

export class ActionReceivedCampaignPublishStatusUpdate implements Action {
    public readonly type = CampaignActionTypes.ReceivedCampaignPublishStatusUpdate;
    constructor(public payload: { campaignPublishStatus: ICampaignPublishStatusUpdate }) {}
}

export class ActionInitPublishCampaign implements Action {
    public readonly type = CampaignActionTypes.InitPublish;
    constructor(public payload?: { filterSelectedAds: boolean }) {}
}
export class ActionInitPublishCampaignSuccess implements Action {
    public readonly type = CampaignActionTypes.InitPublishSuccess;
    constructor(public payload: { ads: IPublishReadyAd[] }) {}
}
export class ActionInitPublishCampaignFailure implements Action {
    public readonly type = CampaignActionTypes.InitPublishFailure;
    constructor(public payload: { error: ICampaignError }) {}
}

export class ActionAssignAdsPublishedToNonHeavyVideoSupport implements Action {
    public readonly type = CampaignActionTypes.AssignPublishedToNonHeavyVideoSupportAdMap;
    constructor(public payload: { adsPublishedToNonHeavyVideoSupport: string[] }) {}
}

export type CampaignActions =
    | ActionLoadCampaign
    | ActionLoadCampaignSuccess
    | ActionRenameCampaign
    | ActionUpdateCampaign
    | ActionInitPublishCampaign
    | ActionInitPublishCampaignSuccess
    | ActionInitPublishCampaignFailure
    | ActionAssignAdsPublishedToNonHeavyVideoSupport
    | ActionReceivedCampaignPublishStatusUpdate
    | ActionReceivedAdPublishStatusesUpdate;
