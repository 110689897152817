import { IDraftCampaign } from '@shared/models/campaigns';
import { DraftCampaignActions, DraftCampaignActionTypes } from './draft-campaign.actions';

export interface IDraftCampaignState {
    draftCampaign: IDraftCampaign;
    liveCampaign: IDraftCampaign;
    lastSaveDate: Date;
    lastPushDate: Date;
    isPushingLive: boolean;
    undoHistory: IDraftCampaign[];
    saving: boolean;
}

const initialState: IDraftCampaignState = {
    draftCampaign: undefined,
    liveCampaign: undefined,
    lastSaveDate: undefined,
    lastPushDate: undefined,
    isPushingLive: false,
    undoHistory: [],
    saving: false
};

export function draftCampaignReducer(
    state: IDraftCampaignState = initialState,
    action: DraftCampaignActions
): IDraftCampaignState {
    switch (action.type) {
        case DraftCampaignActionTypes.LoadSuccess:
            return {
                ...state,
                draftCampaign: action.payload.draftCampaign
            };

        case DraftCampaignActionTypes.LoadLiveCampaignSuccess:
            return {
                ...state,
                liveCampaign: action.payload.liveCampaign
            };

        case DraftCampaignActionTypes.Update:
        case DraftCampaignActionTypes.UpdateAndPushLive:
            return {
                ...state,
                draftCampaign: { ...state.draftCampaign, ...action.payload.changes },
                undoHistory: [...state.undoHistory, state.draftCampaign]
            };

        case DraftCampaignActionTypes.SetHistory:
            return {
                ...state,
                undoHistory: action.payload.history
            };

        case DraftCampaignActionTypes.Save:
            return {
                ...state,
                saving: true
            };

        case DraftCampaignActionTypes.SaveSuccess:
            return {
                ...state,
                draftCampaign: action.payload.draftCampaign,
                lastSaveDate: new Date(),
                saving: false
            };

        case DraftCampaignActionTypes.SaveFailure:
            return {
                ...state,
                saving: false
            };

        case DraftCampaignActionTypes.PushLive: {
            return {
                ...state,
                isPushingLive: true
            };
        }

        case DraftCampaignActionTypes.PushLiveSuccess:
            return {
                ...state,
                undoHistory: [],
                isPushingLive: false,
                lastPushDate: new Date(),
                draftCampaign: { ...state.draftCampaign, isDirty: false }
            };

        case DraftCampaignActionTypes.PushLiveCancelled:
        case DraftCampaignActionTypes.PushLiveFailure:
            return {
                ...state,
                isPushingLive: false
            };

        case DraftCampaignActionTypes.DiscardSuccess:
            return {
                ...state,
                draftCampaign: action.payload.draftCampaign,
                undoHistory: [],
                lastSaveDate: new Date()
            };

        case DraftCampaignActionTypes.SortAllAds:
            return {
                ...state,
                draftCampaign: action.payload.draftCampaign
            };

        default:
            return state;
    }
}
