import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { IAppState } from '@core/core.reducer';
import { BannerflowApiService } from '@core/services/bannerflow';
import { ActionLoadUserSuccess } from '@core/store/user.actions';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserGuard implements CanActivate {
    constructor(
        private store: Store<IAppState>,
        private bannerflowService: BannerflowApiService,
        private router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const accountSlug: string = route.paramMap.get('accountSlug') || undefined;
        const brandSlug: string = route.paramMap.get('brandSlug') || undefined;

        return this.bannerflowService.getUser(accountSlug, brandSlug).pipe(
            take(1),
            tap((user) => {
                if (!user) {
                    this.router.navigate(['404']);
                } else {
                    this.store.dispatch(new ActionLoadUserSuccess({ user }));
                }
            }),
            map((user) => !!user),
            catchError(() => {
                this.router.navigateByUrl('404');
                return of(false);
            })
        );
    }
}
