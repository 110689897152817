import { ICreativePanelAd } from '@campaign/pages/editor/components/creative-panel/model/creative-panel-ad';
import { SizeUtilities } from '@shared/models/common';
import { ICreative, ICreativeSet } from '@shared/models/studio';
import { CreativeUtilities } from '@shared/models/studio/utilities/creative-utilities';
import { ObjectID } from 'bson';
import { IAdCreativePair, IDraftAd } from '../../ad';
import { IAdWithCreativeRef } from '../decision-tree-create-utilities';
import { AD_GROUP_DEFAULT_NAME } from './ad-group.model';

export interface IAdCreativeRefSizePair {
    id: string;
    ad: IAdWithCreativeRef;
}

export class AdGroupUtilities {
    public static groupAdsBySizeAndLanguage(ads: IAdWithCreativeRef[]): IAdWithCreativeRef[][] {
        let limiter = 0;
        const grouped: IAdWithCreativeRef[][] = [];

        // Generating concatinated IDs from Localizations and Size
        // It means that if we get a duplicate size + localization combination we know of it
        const adArray: IAdCreativeRefSizePair[] = ads.map((ad) => ({
            id: ad.localizationId + ad.size.height + ad.size.width,
            ad
        }));

        // The pairRecord-object is used to keep track if we have duplicates of the same size + localization (from the concatinated id) by assigning a value to the key
        // If it exists, create a new array (this will be a new adgroup further down in the process)
        // Note that we use the 'grouped' array of IAd[] from the reducer, and not the acc-object
        adArray.reduce((pairRecord: {}, pair: IAdCreativeRefSizePair) => {
            // Set the record value to 1, since it will be undefined the first time
            pairRecord[pair.id] = pairRecord[pair.id] + 1 || 1;

            // If the value is larger than 0 we need to create a new ad group
            if (pairRecord[pair.id] > limiter) {
                limiter = pairRecord[pair.id];
                grouped.push([]);
            }

            // To keep aligned with the index in the IAdWithCreativeRef array we remove 1
            grouped[pairRecord[pair.id] - 1].push(pair.ad);
            return pairRecord;
        }, {});

        return grouped;
    }

    public static groupAdsByCreativeSet(
        ads: IAdWithCreativeRef[],
        creativesets: ICreativeSet[]
    ): IAdWithCreativeRef[][] {
        // handy dictionary for sorting etc
        const creativesetMap: Map<string, ICreativeSet> = new Map(
            creativesets.map((cs) => [cs.id, cs])
        );

        // sorts ads by version name important for our grouping algorithm
        ads = this.sortAdsByVersion(ads, creativesetMap);

        // groups ads by creativeset id
        let adGroupsByCreativesetId: Map<string, IAdWithCreativeRef[]> = ads.reduce(
            (prev, curr) => {
                const creativesetId: string = curr.creativeRefs[0]?.creativeSetId;

                if (creativesetId) {
                    const defaultGroup: IAdWithCreativeRef[] = prev.get(creativesetId) || [];
                    defaultGroup.push(curr);
                    prev.set(creativesetId, defaultGroup);
                } else {
                    const noCSGroup: IAdWithCreativeRef[] = prev.get('unknown') || [];
                    noCSGroup.push(curr);
                    prev.set('unknown', noCSGroup);
                }

                return prev;
            },
            new Map<string, IAdWithCreativeRef[]>()
        );

        // checks if there are any duplicate versions
        adGroupsByCreativesetId.forEach((adsGroupedByCS, creativeSetId) => {
            const isUnique: any = {};
            const uniqueAds: IAdWithCreativeRef[] = [];
            const duplicateAds: IAdWithCreativeRef[] = [];

            adsGroupedByCS.forEach((ad) => {
                const key: string = ad.localizationId + ad.size.width + ad.size.height;

                if (isUnique.hasOwnProperty(key)) {
                    duplicateAds.push(ad);
                } else {
                    uniqueAds.push(ad);
                    isUnique[key] = false;
                }
            });

            if (duplicateAds.length > 0) {
                // fixes the unique set
                adGroupsByCreativesetId.set(creativeSetId, uniqueAds);

                // group duplicates by version id
                const groupsByVersionId: Map<string, IAdWithCreativeRef[]> = this.groupAdsByVersion(
                    duplicateAds,
                    creativesetMap
                );

                // merge both maps together
                adGroupsByCreativesetId = new Map([
                    ...adGroupsByCreativesetId,
                    ...groupsByVersionId
                ]);
            }
        });

        return Array.from(adGroupsByCreativesetId.values());
    }

    public static sortAdsByCreativeStack(a: ICreativePanelAd, b: ICreativePanelAd): number {
        if (a.hasMultipleCreatives && !b.hasMultipleCreatives) {
            return -1;
        }
        if (!a.hasMultipleCreatives && b.hasMultipleCreatives) {
            return 1;
        }

        // Continue sorting rest of ads by creative sizes
        return CreativeUtilities.sortCreativesByRatioWidthLocale(a.creative, b.creative);
    }

    private static groupAdsByVersion(
        ads: IAdWithCreativeRef[],
        creativesetMap: Map<string, ICreativeSet>
    ): Map<string, IAdWithCreativeRef[]> {
        const adsGroupedByVersionId: Map<string, IAdWithCreativeRef[]> = new Map();

        const duplicateAdCreativePairs: IAdCreativePair[] = ads.map((ad) => {
            const creative: ICreative = this.getCreativeOfAd(ad, creativesetMap);

            return {
                ad,
                creative
            };
        });

        duplicateAdCreativePairs.forEach((pair) => {
            const { ad, creative } = pair;

            // Finds key of map object (ad group) which the ad can be paired with
            const keys: string[] = Array.from(adsGroupedByVersionId.keys());
            const matchingArrayKey: string = keys.find((key) => {
                const group: IAdWithCreativeRef[] = adsGroupedByVersionId.get(key);

                // Checks if this ad exists in ad group already and should be split
                const hasIdenticalAd: boolean = group.some(
                    (currAd) =>
                        ad.localizationId === currAd.localizationId &&
                        SizeUtilities.isSameSize(currAd.size, ad.size)
                );

                // Group version of same version & language into same ad group. Don't mix them.
                const hasCommonVersion = !group.some((currAd) => {
                    const adCreative: ICreative = this.getCreativeOfAd(currAd, creativesetMap);

                    return (
                        adCreative.version.id !== creative.version.id &&
                        ad.localizationId === currAd.localizationId
                    );
                });

                return !hasIdenticalAd && hasCommonVersion;
            });

            if (matchingArrayKey) {
                // Add ad to existing ad group
                const group: IAdWithCreativeRef[] =
                    adsGroupedByVersionId.get(matchingArrayKey) || [];
                group.push(ad as IAdWithCreativeRef);
                adsGroupedByVersionId.set(matchingArrayKey, group);
            } else {
                // Split ad to its new ad group
                const group: IAdWithCreativeRef[] = [];
                group.push(ad as IAdWithCreativeRef);

                const newKey: string = (keys.length + 1).toString();
                adsGroupedByVersionId.set(newKey, group);
            }
        });

        return adsGroupedByVersionId;
    }

    private static getCreativeOfAd(
        ad: IAdWithCreativeRef,
        creativesetMap: Map<string, ICreativeSet>
    ): ICreative {
        return creativesetMap
            .get(ad.creativeRefs[0].creativeSetId)
            .creatives.find((c) => c.id === ad.creativeRefs[0].creativeId);
    }

    private static sortAdsByVersion(
        ads: IAdWithCreativeRef[],
        creativesetMap: Map<string, ICreativeSet>
    ): IAdWithCreativeRef[] {
        return [...ads].sort((a, b) => {
            const versionA: string = this.getAdVersionName(a, creativesetMap);
            const versionB: string = this.getAdVersionName(b, creativesetMap);

            return versionA?.localeCompare(versionB);
        });
    }

    private static getAdVersionName(
        ad: IAdWithCreativeRef,
        creativesetMap: Map<string, ICreativeSet>
    ): string {
        const assignedCreativesetId: string = ad.creativeRefs[0]?.creativeSetId;
        const assignedCreativeId: string = ad.creativeRefs[0]?.creativeId;
        const creativeSet: ICreativeSet = creativesetMap.get(assignedCreativesetId);

        const creative: ICreative = creativeSet?.creatives?.find(
            (c) => c.id === assignedCreativeId
        );

        return creative?.version.name;
    }

    public static identicalAds(ad: IDraftAd, adList: IDraftAd[]): boolean {
        return adList.some(
            (adObj) =>
                adObj.size.width === ad.size.width &&
                adObj.size.height === ad.size.height &&
                adObj.localizationId === ad.localizationId
        );
    }

    public static copyAd(draftAd: IDraftAd): IDraftAd {
        return { ...draftAd, id: new ObjectID().toString() };
    }

    public static generateDefaultAdGroupNameIndex(currentAdGroupNames: string[]): number {
        const defaultNameWithIndexRegex = new RegExp(`^${AD_GROUP_DEFAULT_NAME} \\d+$`, 'i');

        const defaultAdGroupNamexIndexes = currentAdGroupNames
            .filter((name) => name.match(defaultNameWithIndexRegex))
            .map((nameWithNumber) => nameWithNumber.match(/\d+/)[0])
            .map((numberAsString) => +numberAsString);

        return defaultAdGroupNamexIndexes.length ? Math.max(...defaultAdGroupNamexIndexes) + 1 : 1;
    }
}
